@import './themes.scss';
@import './fonts.scss';

html, #root {
  background-color: var(--background);
}

h1, h2, h3, h4, h5, p {
  color: var(--color-primary);
  font-family: nyt-cheltenham, serif;
}
p a:hover {
  text-decoration: none;
}

.row, .col {
  margin-left: 0px !important;
  margin-right: 0px !important;
  padding-left: 0px;
  padding-right: 0px;
}

.page-width {
  padding-left: 2%;
  padding-right: 2%;
}

.fit-content {
  width: fit-content;
}

.App {
}


@media only screen and (min-width: 900px) {
  .MainContent {
    padding: 20px 0px;
    display: grid;
    grid-column-gap: calc(1rem * 2 + 1px);
    grid-template-columns: repeat(20,1fr);
  }
}
@media only screen and (max-width: 900px) {
  .MainContent {
    padding: 20px 0px;
    display: grid;
    grid-template-columns: 1fr;
  }
}

.main-column-left {
  min-width: 0;
  height: fit-content;
  position: relative;
  grid-column: span 14;
}
.main-column-left > *:not(:last-child),
.main-column-right > *:not(:last-child)  {
  border-bottom: 1px solid var(--divider-dark);
  padding-bottom: 20px;
}
.main-column-left > *:not(:first-child),
.main-column-right > *:not(:first-child) {
  margin-top: 20px;
}

.main-column-right {
  min-width: 0;
  position: relative;
  grid-column: span 6;
}
.main-column-right:not(:first-child)::before {
    content: '';
    height: 100%;
    position: absolute;
    left: calc(-1rem - 1px);
    border-left: 1px solid var(--divider-light);
}

.article-title {

}
.article-subtitle {

}
.article-intro {

}


.FrontPage, .Login, .Tech, .Business, .Sports, .Arts, .Books,
.Fashion, .Food, .Drink, .Travel, .GameOfLife, .Games {
  margin: 0px auto;
  padding: 0px 3%;
  max-width: 1200px;
  box-sizing: content-box;
}
.Article, .NewArticle {
  box-sizing: content-box;
}
@media only screen and (max-width: 600px) {
  .FrontPage, .Login, .Tech, .Business, .Sports, .Arts, .Books,
  .Fashion, .Food, .Drink, .Travel, .GameOfLife, .Games {
    margin: 0px auto;
    padding: 0px;
    box-sizing: content-box;
    width: -webkit-fill-available;
  }

  .FrontPage > :not(.nav-divider-bar-sm),
  .Arts > :not(.nav-divider-bar-sm),
  .Drink > :not(.nav-divider-bar-sm),
  .GameOfLife > :not(.nav-divider-bar-sm),
  .Games > :not(.nav-divider-bar-sm),
  .Article > :not(.nav-divider-bar-sm){
  }
}


.line-height-sm {
  line-height: 18px;
}

.NavBar {
  display: block;
}

.NavBarMini:not(.always-shown) {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .NavBar {
    display: none;
  }
  .NavBarMini:not(.always-shown) {
    display: block;
  }
}

.nav-top {
  height: 30px;
}

.nav-top-mini {
  height: 0px;
}

.nav-header {
  align-items: center;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 5px;
}
.nav-header-mini {
  align-items: center;
  justify-content: center;
  padding: 10px 0px;
  border-bottom: 1px solid var(--nav-divider-top);
}
.display-grid {
  display: grid;
}

/* Style the toggle button */
#nav-list {
  display: block;
  position: relative;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  line-height: 11px;
  padding: 8px 16px;
}
#nav-x {
  display: none;
  background: none;
  position: relative;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  line-height: 11px;
  padding: 8px 16px;
}

#nav-account {
  display: block;
  position: relative;
  cursor: pointer;
  border: 0;
  background-color: transparent;
  line-height: 11px;
  padding: 4px 12px;
}
#nav-list:hover, #nav-x:hover, #nav-account:hover {
  background-color: #f7f7f7;
}
#nav-list:focus, #nav-x:focus, #nav-account:focus {
  background-color: lightblue;
}
#nav-list-svg, #nav-x-svg {
  display: inline-block;
  height: 16px;
  vertical-align: middle;
  width: 16px;
}
#nav-account-svg {
  display: inline-block;
  height: 24px;
  vertical-align: middle;
  width: 24px;
}
#nav-title {
  display: flex;
  align-items: center;
  font-family: nyt-franklin;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #121212;
}

@media only screen and (max-width: 600px) {
  #nav-menu:hover, #nav-x:hover , #nav-account:hover  {
    background-color: transparent;
  }
}

.nav-open {
  display: block !important;
  animation: slide-in-left .5s ease forwards;
}
.nav-closed {
  display: block !important;
  animation: slide-out-left .5s ease forwards;
}

@keyframes slide-in-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes slide-out-left {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.nav-links-row {
  display: flex;
  padding: 6px 0px;
  border-top: 1px solid var(--nav-divider-top);
  border-bottom: 5px double var(--nav-divider-bottom);
}
.nav-links {
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 12px;
  margin: 0px;
}


#nav-menu {
  display: none;
  position: fixed;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 999;
  overflow-y: auto;
}
.nav-links-row-mini {
  display: flex;
  padding: 20px 0px;
}
.nav-links-mini {
  list-style-type: none;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
  margin: 0px;
}
.nav-link-mini {
  color: rgb(51, 51, 51);
  display: block;
  font-family: nyt-franklin, helvetica, arial, sans-serif;
  font-size: 15px;
  font-weight: 500;
  line-height: 34px;
  text-decoration: none;
  width: fit-content;
  padding: 0px 20px;
}
.nav-link-mini:hover {
  color: rgb(51, 51, 51);
  background-color: #f7f7f7;
}
.nav-link-mini:focus {
  color: white;
  background-color: lightblue;
}
.centered-nav-item {
  display: flex;
  justify-content: center;
}

.equal-width-numbers {
  font-variant-numeric: tabular-nums;
}

.nav-text {
  font-size: 12px;
  color: var(--color-primary)
}

#nameLogo {
  enable-background: new 0 0 839 134.4;
  fill: var(--color-primary);
}
#nameLogoSmall svg{
  fill: var(--color-primary);
  height: 24px;
}

a.nav-link {
  color: var(--color-primary);
  font-size: 12px;
  font-family: nyt-franklin;
}
a.nav-link:hover {
  color: var(--color-primary-hover);
}

#main-logo {
  display: flex;
  justify-content: center;
  height: 60px;
  padding: 0px;
}
#main-logo-mini {
  display: flex;
  justify-content: center;
  height: 30px;
  padding: 0px;
}
#main-nav-left {
  display: flex;
  justify-content: flex-start;
  padding: 0px;
}
#main-nav-right {
  display: flex;
  justify-content: flex-end;
  padding: 0px;
}
.nav-divider-bar-sm {
  background: #f7f7f7;
  border-top: 1px solid #e2e2e2;
  border-bottom: 1px solid #e2e2e2;
  padding: 8px;
}

.always-shown > .nav-header-mini > #main-nav-left {
  padding-left: 4px;
}
.always-shown > .nav-header-mini > #main-nav-right {
  padding-right: 4px;
}

@media only screen and (max-width: 600px) {
  .always-shown > .nav-header-mini > #main-nav-left {
    padding-left: 4px;
  }
  .always-shown > .nav-header-mini > #main-nav-right {
    padding-right: 4px;
  }
  #main-nav-left {
    padding-left: 4px;
  }
  #main-nav-right {
    padding-right: 4px;
  }
}

/* Color Palette */
.color-theme-btn, .color-menu-btn {
  border: 0px;
  border-radius: 50%;
  margin: 3px;
  height: 35px;
  width: 35px;
}
#colorMenu {
  padding: 5px 5px 0px 5px;
}
#paintPalette {
  position: absolute;
  width: min-content;
  background: var(--background);
  border: 1px solid var(--color-primary);
  z-index: 1000;
  padding: 0px 5px 5px 5px;
  border-radius: 10px;
}
.hidden {
  display: none !important;
}
#color-theme-palette-menu {
  background: var(--background-color);
  margin: 5px 5px;
  filter: grayscale(100%);
}
#color-theme-palette-dark {
  background: var(--deep-blue4);
}
#color-theme-palette-red {
  background: var(--light-red);
}
#color-theme-palette-orange {
  background: var(--light-orange);
}
#color-theme-palette-yellow {
  background: var(--light-yellow);
}
#color-theme-palette-green {
  background: var(--light-green);
}
#color-theme-palette-blue {
  background: var(--light-blue);
}
#color-theme-palette-purple {
  background: var(--light-purple);
}
#color-theme-palette-bw {
  background: var(--white);
  border: 1px solid var(--black);
}
#color-theme-palette-bluered {
  background: var(--light-blue);
  border: 1px solid var(--red);
}
#color-theme-palette-greenyellow {
  background: var(--light-green);
  border: 1px solid var(--yellow);
}
#color-theme-palette-bluegreen {
  background: var(--light-blue);
  border: 1px solid var(--green);
}

/* Clock Start */

.clock-container {
  width: 70px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clock {
  border: 2px solid black;
  box-shadow: .1rem .1rem .5rem grey, -.1rem -.1rem .5rem grey;
  background-color: #ededed;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* .numbers {
  width: inherit;
  height: inherit;
  position: relative;
  display: none;
} */

/* .numbers div {
  position: absolute;
  font-size: .8rem;
} */

/* .clock-twelve {
  top: .2rem;
  left: 50%;
  transform: translateX(-50%);
}

.clock-three {
  right: .2rem;
  top: 50%;
  transform: translateY(-50%);
}

.clock-six {
  bottom: .2rem;
  left: 50%;
  transform: translateX(-50%);
}

.clock-nine {
  left: .2rem;
  top: 50%;
  transform: translateY(-50%);
} */

.arrows {
  width: inherit;
  height: inherit;
  position: absolute;
  top: 0;
  left: -.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrows::before {
  content: "";
  width: .5rem;
  height: .5rem;
  background-color: black;
  border-radius: 50%;
  z-index: 10;
}

.arrows div {
  width: .2rem;
  height: 1.7rem;
  background-color: black;
  position: absolute;
  bottom: 50%;
  border-radius: 100% 100% 0 0;
  transform-origin: bottom center;
}

.arrows .clock-second {
  height: 1.7rem;
  background-color: red;
  transform: rotate(270deg);
}

.arrows .clock-hour {
  height: 1.2rem;
  transform: rotate(90deg);
}

/* Clock End */


/* Main Content Start */
.primary-story-container {

}
.primary-story-title-container {

}
.primary-story-content-container {
  display: grid;
  grid-column-gap: calc(1rem * 2 + 1px);
  grid-template-columns: repeat(14,1fr);
}
.primary-story-left-column {
  grid-column: span 5;
}
.primary-story-left-column > *:not(:last-child)::after {
  content: '';
  display: block;
  border-bottom: 1px solid var(--divider-light);
  margin-top: 1rem;
  margin-bottom: 1rem;
  clear: both;
  grid-column: 1/-1;
}

.primary-story-right-column {
  grid-column: span 9;
}
.primary-story-section {

}

.primary-story-title {
  text-align: center;
  font-family: 'nyt-cheltenham', serif;
  margin: 0 auto;
  margin-bottom: 16px;
  max-width: 780px;
}

.column-section-2 {
  display: grid;
  grid-column-gap: calc(1rem * 2 + 1px);
  grid-template-columns: repeat(14,1fr);
}
.column-section-1-of-2 {
  min-width: 0;
  position: relative;
  grid-column: span 7;
}
.column-section-1-of-2:not(:first-child)::before {
  content: '';
  height: 100%;
  position: absolute;
  left: calc(-1rem - 1px);
  bottom: 0;
  border-left: 1px solid var(--color-stroke-tertiary, #C7C7C7);
}

@media only screen and (max-width: 900px) {
  .primary-story-title {
    font-size: 32px;
    line-height: 37px;
    font-weight: 700;
  }
  .primary-story-content-container {
    display: grid;
    grid-column-gap: calc(1rem * 2 + 1px);
    grid-template-columns: repeat(14,1fr);
  }
}
@media only screen and (max-width: 600px) {
  .primary-story-title {

  }
  .primary-story-content-container {
    display: grid;
    grid-column-gap: 0px;
    grid-template-columns: 1fr;
  }
  .main-column-left {
    margin: 0px 20px;
  }
  .main-column-right {
    display: none;
  }

  .column-section-2 {
    display: grid;
    grid-column-gap: calc(1rem * 2 + 1px);
    grid-template-columns: repeat(6,1fr);
  }
  .column-section-1-of-2 {
    min-width: 0;
    position: relative;
    grid-column: span 6;
  }
  .column-section-1-of-2:not(:first-child)::before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-left: 0;
    border-top: 2px solid var(--divider-dark, #C7C7C7);
    margin-bottom: 15px;
  }
  .column-section-1-of-2:not(:first-child) {
    margin-top: 30px;
  }
}

.secondary-story-title {
  font-family: 'nyt-cheltenham';
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 8px;
}
.secondary-story-summary {
  font-family: 'nyt-imperial';
  font-size: 14px;
}

.primary-story-subtitle {
  font-family: 'nyt-cheltenham', serif;
}
.primary-story-summaries {
  list-style: none;
  color: var(--color-secondary);
  font-size: 0.875rem;
  letter-spacing: 0.1px;
  line-height: 1.1875rem;
  margin: 0;
  padding: 0;
  position: relative;
}
.primary-story-summaries li::before {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    left: -8px;
    top: 8px;
    content: '';
    display: block;
    background: var(--color-secondary);
    position: absolute;
}
.primary-story-summary {
  font-size: 14px;
  color: var(--color-secondary);
  line-height: 19px;
  margin: 6px 0 0 0;
  position: relative;
  letter-spacing: 0.1px;
}

.story-offshoot {
  font-size: 16px;
  line-height: 20px;
}
.story-image-container {

}
.story-image {
  background-color: transparent;
  display: block;
  width: 100%;
  animation: 300ms ease-in 0s 1 normal forwards running animation-n5y8bo;
}
.story-image-footnote {

}
.story-image-text {

}
.story-image-credit {

}

.primary-story-offshoot {
  font-size: 16px;
  line-height: 20px;
}
.primary-story-image-container {

}
.primary-story-image {
  background-color: transparent;
  display: block;
  width: 100%;
  animation: 300ms ease-in 0s 1 normal forwards running animation-n5y8bo;
}
.primary-story-image-footnote {

}
.primary-story-image-text {

}
.primary-story-image-credit {

}


/* Main Content End */

/* Text Style Classes Start */
.bold {
  font-weight: bold;
}
.bullet {
  display: list-item;
}
/* Text Style Classes End */

/* Generic Page Start */

.page-header {
  margin: 20px 0px 20px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.page-header-title {
  text-align: center;
}

.page-body {
  display: flex;
  direction: rtl;
  justify-content: center;
  grid-gap: 1vw;
  margin-right: 4vw;
}

/* Generic Page End */

/* Start Account */
.account-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 48px;
}
.account-content hr{
  border-right: none;
  border-bottom: none;
  border-left: none;
  border-image: initial;
  border-top: 2px solid #121212;
  margin: 0 0 12px 0;
  opacity: 1;
}
.account-section {
  width: 90%;
}
#account-header {
  margin-top: 48px;
}
#account-header h1{
  font-size: 40px;
  line-height: 48px;
  font-family: nyt-cheltenham;
  font-weight: 300;
  color: #121212;
  margin: 0;
}
#account-header p{
  font-size: 16px;
  line-height: 24px;
  font-family: nyt-franklin;
  font-weight: 500;
  color: #121212;
  margin: 0;
}
#account-details h1{
  font-size: 22px;
  line-height: 28px;
  font-family: nyt-franklin;
  font-weight: 700;
  color: #121212;
}
#account-details p{
  font-size: 16px;
  line-height: 24px;
  font-family: nyt-franklin;
  font-weight: 500;
  color: #121212;
}

/* End Account */

/* Start Login */

.login-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form {
  margin-top: 40px;
  width: 450px;
}
.login-header {
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
#loginHeaderTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  line-height: 40px;
  margin: 0px;
}
.login-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-gap: 16px;
  margin-right: 4vw;
  padding: 16px 0;
  width: 100%;
}
.login-field {
  display: flex;
  flex-direction: column;
}
.login-field label {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  font-family: nyt-franklin;
}
#emailInputFieldError {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  font-family: nyt-franklin, Helvetica;
  color: #A61717;
  display: none;
}
#passwordInputFieldError {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  font-family: nyt-franklin, Helvetica;
  color: #A61717;
  display: none;
}
.login-input-group {
  margin: 0;
  padding: 0;
  border: 1px solid #888888;
  border-radius: 3px;
  transition: border-color .15s ease-in 0s;
  outline: none;
  opacity: 1;
}
.login-input-group.faded {
  opacity: 0.4;
}
.login-field input {
  width: 100%;
  border: 0;
  font-size: 1rem;
  font-weight: 500;
  font-family: nyt-franklin;
  height: 44px;
  padding: 0 12px;
}
.login-field input[type=password]{
  letter-spacing: 3px;
}
.login-field input:disabled {
  background-color: #FFFFFF !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.login-field input:focus {
  outline: 0;
}
.input-btn {
  color: #000000;
  transition: all 0.1s ease-in-out 0s;
  margin: 0;
  padding: 0;
  height: 44px;
  float: right;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  font-family: nyt-franklin;
  width: 56px;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  border: 0px;
  cursor: pointer;
  white-space: nowrap;
  background-color: #FFFFFF;
}
.input-btn:hover {
  background-color: #f7f7f7;
}
.login-btn {
  background-color: #121212;
  color: white;
  height: 44px;
  line-height: 38px;
  font-family: nyt-franklin;
  font-size: 16px;
  font-weight: 700;
  border: 0.0625em solid #121212;
  border-radius: 3px;
}
.login-btn:hover {
  background-color: #333333;
}
.login-forgot-password {
  margin: 0;
  line-height: normal;
  display: flex;
}
.login-forgot-password-text {
  margin: 0;
  padding: 0;
  font-size: 14px;
  line-height: 21px;
  white-space: nowrap;
  cursor: pointer;
  font-weight: 500;
  text-decoration: underline;
  font-family: nyt-franklin;
}
#passwordInputGroup input {
  width: calc(100% - 56px);
}
.input-group-error {
  border: 1px solid #CC0000;
}


@media only screen and (max-width: 600px) {
  .login-form {
    margin-top: 40px;
    width: 80%;
  }
}

/* End Login */

/* Drink Start */

.Drink {
}
.drink-header {
  margin: 20px 0px 20px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.drink-header-title {
  text-align: center;
}

.drink-body {
  display: flex;
  direction: rtl;
  justify-content: center;
  grid-gap: 1vw;
  margin-right: 4vw;
}
.drink-chart-week{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  grid-gap: 1vw;
}
.drink-chart-day {
  height: 4vw;
  width: 4vw;
  color: white;
}
.day-label {
  height: 4vw;
  width: 4vw;
  text-align: right;
  font-weight: bold;
  font-size: 2vw;
  vertical-align: middle;
}
.drink-chart-day-color-1 {
  background: var(--light-blue);
}
.drink-chart-day-color-2 {
  background: var(--blue);
}
.drink-chart-day-color-3 {
  background: var(--deep-blue4);
}
.drink-chart-day-color-4 {
  background: var(--deep-blue3);
}
.drink-chart-day-color-5 {
  background: var(--deep-blue1);
}

/* Drink End */

/* Game of Life Start */

.GameOfLife {
}
.game-of-life-header {
  margin: 20px 0px 10px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.game-of-life-header-title {
  text-align: center;
  font-family: monospace;
  font-size: 24px;
  margin-bottom: 10px;
  color: #326891;
}
.game-of-life-header-title:hover {
  color: #326891;
  text-decoration: none;
}
.game-of-life-header-buttons {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  flex-direction: row;
  margin-left: 20%;
  margin-right: 20%;
}
.game-of-life-header-count {
  text-align: center;
  font-family: monospace;
  margin-top: 10px;
}
#playPauseButton {
  margin-right: 13.81px;
}
.game-of-life-line {
  display: flex;
  justify-content: space-between;
  background: var(--game-of-life-background);
}
.game-of-life-space {
  font-family: monospace;
  font-size: 2vw;
  line-height: 1.6vw;
  min-height: 1.6vw;
  min-width: 1.2vw;
  background: var(--game-of-life-background);
  color: var(--game-of-life-primary);
  cursor: pointer;
}
.game-of-life-space:hover {
  background: var(--game-of-life-hover);
  color: var(--game-of-life-background);
}

@media only screen and (max-width: 740px) {
  .game-of-life-line {
    display: flex;
    justify-content: space-between;
    background: var(--game-of-life-background);
  }
  .game-of-life-space {
    font-family: monospace;
    font-size: 6vw;
    line-height: 4.8vw;
    min-height: 4.8vw;
    min-width: 3.6vw;
    background: var(--game-of-life-background);
    color: var(--game-of-life-primary);
    cursor: pointer;
  }
}

/* Game of Life End */

/* Games Start */

.games-header {
  margin: 20px 0px 20px 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.games-header-title {
  text-align: center;
}

/* Games End */

/* New Article Start */
.ArticleNew .article-title {
  opacity: .5;
  font-style: italic;
  outline: 0;
}
/* New Article End */

/* Article Start */

.article-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.article-header {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.article-body {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.article-paragraph p {
  font-family: nyt-imperial;
  font-size: 20px;
  max-width: 600px;
}
.article-paragraph p a{
  color: #326891;
}
.article-paragraph p strong{
  font-weight: 700;
}

@media only screen and (max-width: 740px) {
  .article-paragraph p {
    font-size: 18px;
  }
}

.article-type {
  font-family: nyt-cheltenham;
  font-weight: 700;
  font-size: 16px;
  color: #D0021B;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  max-width: 600px;
}
.article-type:hover {
  color: #D0021B;
  text-decoration: underline;
  cursor: pointer;
}
.article-type-secondary {
  font-family: nyt-cheltenham;
  font-weight: 700;
  font-size: 16px;
  padding: 0px 25px 16px 25px;
  margin: 0px;
  border-bottom: 1px solid #EBEBEB;
  text-transform: uppercase;
  width: fit-content;
  max-width: 600px;
}
.article-title-wide {
  font-family: nyt-cheltenham,serif;
  font-size: 47px;
  font-weight: 200;
  margin: 20px 0px 8px 0px;
  text-align: left;
  line-height: 55px;
  max-width: 600px;
}
.article-subtitle-wide {
  font-family: nyt-cheltenham,serif;
  font-size: 23px;
  font-weight: 300;
  margin: 0px 0px 30px 0px;
  text-align: left;
  line-height: 30px;
  max-width: 600px;
}
.article-title-standard {
  font-family: nyt-cheltenham,serif;
  font-size: 40px;
  font-style: italic;
  font-weight: 700;
  margin: 30px 0px 18px 0px;
  text-align: left;
  line-height: 55px;
  max-width: 600px;
}
.article-title-skinny {
  font-family: nyt-cheltenham-cond,serif;
  font-size: 57px;
  line-height: 60px;
  text-align: center;
  margin: 30px 0px 18px 0px;
  max-width: 600px;
}
.article-date {
  font-family: nyt-franklin;
  font-weight: 500;
  font-size: 13px;
  color: #363636;
  max-width: 600px;
}
.article-date-lg {
  margin-bottom: 32px;
  text-align: center;
}
.article-date-sm {
  margin-bottom: 0px;
  text-align: left;
}
.article-image {
  margin-bottom: 40px;
}
.article-image-lg {
  max-width: none;
  width: 100%;
}
.article-image-md {
  max-width: 945px;
  width: 100%;
}
.article-image-sm {
  max-width: 600px;
  width: 100%;
}
.article-image-content {
  width: 100%;
  aspect-ratio: auto 600 / 600;
}
.article-image-metadata {
  margin: 10px 20px 0 0;
  line-height: 16px;
}
.article-image-lg > .image-metadata {
  margin: 10px 20px 0 20px;
}
.article-image-description {
  display: inline-flex;
  font-family: nyt-imperial;
  font-size: 15px;
  color: #727272;
  margin-right: 7px;
}
.article-image-credit {
  font-family: nyt-imperial;
  font-size: 13px;
  color: #727272;
  letter-spacing: .13px;
}

@media only screen and (max-width: 1024px) and (min-width: 740px) {
  .article-image-md {
    max-width: none;
    width: 100%;
  }
  .article-image-md > .article-image-metadata {
    margin: 10px 20px 0 20px;
  }
}

@media only screen and (max-width: 740px) {
  .article-title-wide {
    font-family: nyt-cheltenham,serif;
    font-size: 36px;
    font-weight: 200;
    margin: 0px 20px 8px 20px;
    text-align: left;
    line-height: 39px;
  }
  .article-subtitle-wide {
    font-family: nyt-cheltenham,serif;
    font-size: 20px;
    font-weight: 300;
    margin: 0px 20px 30px 20px;
    text-align: left;
    line-height: 25px;
    max-width: 600px;
  }
  .article-title-standard {
    font-family: nyt-cheltenham,serif;
    font-size: 40px;
    font-style: italic;
    font-weight: 700;
    margin: 30px 20px 18px 20px;
    text-align: left;
    line-height: 55px;
  }
  .article-title-skinny {
    font-family: nyt-cheltenham-cond,serif;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    margin: 30px 20px 18px 20px;
  }
  .article-image {
    max-width: none;
    width: 100%;
  }
  .article-image-metadata {
    margin: 10px 20px 0 20px;
  }
  .article-image-description {
    font-size: 14px;
    margin-right: 7px;
  }
  .article-image-credit {
    font-size: 12px;
    letter-spacing: .12px;
  }
}

.article-header-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.article-header-top-lg {

}
.article-header-top-md {

}
.article-header-top-sm {

}

.article-header-bottom {
  display: flex;
  flex-direction: column;
  width: inherit;
  max-width: 600px;
}
.article-header-bottom-lg {
  margin-bottom: 30px;
}
.article-header-bottom-md {
  margin-bottom: 4px;
}
.article-header-bottom-sm {
  margin-bottom: 20px;
}

@media only screen and (max-width: 640px) {
  .article-header-bottom {
    margin-left: 20px;
    margin-right: 20px;
  }
}


.article-author {
  display: flex;
  flex-direction: row;
}
.article-author-lg {
  flex-direction: row;
  border-top: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  padding: 10px 0;
}
.article-author-md {
  padding: 10px 0;
}
.article-author-sm {
}

.author-image {
  margin-right: 10px;
}
.author-image-lg {
  width: 80px;
  height: 80px;
}
.author-image-md {
  width: 50px;
  height: 50px;
}

.author-metadata {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.author-title {
  font-family: nyt-franklin;
  font-weight: 700;
  color: #363636;
  margin: 0;
}
.author-title-lg {
  font-size: 16px;
}
.author-title-md {
  font-size: 15px;
}
.author-title-sm {
  font-size: 15px;
}
.author-name a {
  color: #363636;
}
.author-name a:hover {
  color: #363636;
}

.author-bio {
  font-family: nyt-franklin;
  font-size: 15px;
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  .author-image {
    width: 40px;
    height: 40px;
  }
  .author-title-lg {
    font-size: 13px;
  }
  .author-title-md {
    font-size: 14px;
  }
  .author-bio {
    font-size: 13px;
  }
}

.article-footer {
  display: flex;
  flex-direction: column;
  margin: 0 35px;
  padding: 0 35px;
  border-top: 1px solid #EBEBEB;
}
.article-footer ul {
  list-style: none;
  padding: 0px;
  display: inline-block;
}
.article-footer li a {
  font-family: nyt-franklin;
  font-size: 11px;
  padding: 10px;
  color: #666666;
  text-decoration: none;
}
.article-footer li:hover {
  color: #666666;
  text-decoration: underline;
}

@media only screen and (max-width: 600px) {
  .article-footer {
    display: flex;
    flex-direction: column;
    margin: 0 20px;
    padding: 0 20px;
    border-top: 1px solid #EBEBEB;
  }
}
/* Article End */

/* Loading Animation Start */
.pulse-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 170px;
}

.pulse {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #666666;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Loading Animation End */

/* NewArticle Start */

.create-article {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.create-article > .Article > .NavBar {
  display: none;
}
.author-menu {
  display: flex;
  flex-direction: column;
  border: 1px solid #326891;
  border-radius: 2px;
  margin: 20px;
  padding: 10px 20px;
  width: 600px;
}
.author-tools {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
}
.author-tool {
  margin: 0 10px;
  color: #326891;
  font-size: 20px;
  font-weight: 500;
  font-family: nyt-cheltenham;
}
.author-tool:hover {
  cursor: pointer;
  text-decoration: none;
  color: #326891;
  user-select: none;
}

#create-article-form {
  display: flex;
  flex-direction: column;
  width: 600px;
  border: 1px dashed #326891;
  border-radius: 2px;
  padding: 20px;
}
#create-article-form label {
  color: #326891;
  font-size: 20px;
  font-weight: 500;
  font-family: nyt-cheltenham;
}
#create-article-form input {
  color: #326891;
  font-size: 16px;
  font-weight: 500;
  font-family: nyt-franklin;
}
.form-title {
  color: #326891;
  font-size: 30px;
  font-weight: 700;
  font-family: nyt-cheltenham;
  text-align: center;
}
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 100%;
}
.form-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}
.form-button {
  margin: 0 10px;
  color: #326891;
  padding: 5px;
  border: 1px solid transparent;
}
.form-button:hover {
  cursor: pointer;
  text-decoration: none;
  color: #326891;
  user-select: none;
}
.form-button-selected {
  border: 1px solid #326891;
  border-radius: 2px;
  background-color: white;
}

/* NewArticle End */

/* Masters Start */

.masters-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.masters-header {
  width: -webkit-fill-available;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.masters-header a {
  text-decoration: none;
}

.masters-body {
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.masters-paragraph p {
  font-family: nyt-imperial;
  font-size: 20px;
  max-width: 600px;
}
.masters-paragraph p a{
  color: #326891;
}
.masters-paragraph p strong{
  font-weight: 700;
}

@media only screen and (max-width: 740px) {
  .masters-paragraph p {
    font-size: 18px;
  }
}

.masters-type {
  font-family: nyt-cheltenham;
  font-weight: 700;
  font-size: 16px;
  color: #006747;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  max-width: 600px;
}
.masters-type:hover {
  color: #009b77;
  text-decoration: underline;
  cursor: pointer;
}
.masters-type-secondary {
  font-family: nyt-cheltenham;
  font-weight: 700;
  font-size: 16px;
  padding: 16px 25px 16px 25px;
  margin: 0px;
  text-transform: uppercase;
  width: fit-content;
  max-width: 600px;
}
.masters-type-paragraph {
  font-family: nyt-cheltenham;
  font-weight: 700;
  font-size: 16px;
  font-style: italic;
  padding: 16px 25px 16px 25px;
  margin: 0px;
  width: fit-content;
  max-width: 600px;
}
.masters-type-paragraph-small {
  font-family: nyt-cheltenham;
  font-weight: 700;
  font-size: 16px;
  font-style: italic;
  padding: 0px 25px;
  margin: 0px;
  width: fit-content;
  max-width: 600px;
}
.masters-title-wide {
  font-family: nyt-cheltenham,serif;
  font-size: 47px;
  font-weight: 200;
  margin: 30px 0px 18px 0px;
  text-align: left;
  line-height: 46px;
  max-width: 600px;
}
.masters-title-standard {
  font-family: nyt-cheltenham,serif;
  font-size: 40px;
  font-style: italic;
  font-weight: 700;
  margin: 20px 0px 18px 0px;
  text-align: left;
  line-height: 55px;
  max-width: 600px;
}
.masters-title-skinny {
  font-family: nyt-cheltenham-cond,serif;
  font-size: 57px;
  line-height: 60px;
  text-align: center;
  margin: 30px 0px 18px 0px;
  max-width: 600px;
}
.masters-date {
  font-family: nyt-franklin;
  font-weight: 500;
  font-size: 13px;
  color: #363636;
  max-width: 600px;
}
.masters-date-lg {
  margin-bottom: 32px;
  text-align: center;
}
.masters-date-sm {
  margin-bottom: 0px;
  text-align: left;
}
.masters-image {
  margin-bottom: 40px;
}
.masters-image-lg {
  max-width: none;
  width: 100%;
}
.masters-image-md {
  max-width: 945px;
  width: 100%;
}
.masters-image-sm {
  max-width: 600px;
  width: 100%;
}
.masters-image-content {
  width: 100%;
  aspect-ratio: auto 600 / 600;
}
.masters-image-metadata {
  margin: 10px 20px 0 0;
  line-height: 16px;
}
.masters-image-lg > .masters-image-metadata {
  margin: 10px 20px 0 20px;
}
.masters-image-description {
  display: inline-flex;
  font-family: nyt-imperial;
  font-size: 15px;
  color: #727272;
  margin-right: 7px;
}
.masters-image-credit {
  font-family: nyt-imperial;
  font-size: 13px;
  color: #727272;
  letter-spacing: .13px;
}

@media only screen and (max-width: 1024px) and (min-width: 740px) {
  .masters-image-md {
    max-width: none;
    width: 100%;
  }
  .masters-image-md > .masters-image-metadata {
    margin: 10px 20px 0 20px;
  }
}

@media only screen and (max-width: 740px) {
  .masters-title-wide {
    font-family: nyt-cheltenham,serif;
    font-size: 47px;
    font-weight: 200;
    margin: 30px 20px 18px 20px;
    text-align: left;
    line-height: 46px;
  }
  .masters-title-standard {
    font-family: nyt-cheltenham,serif;
    font-size: 40px;
    font-style: italic;
    font-weight: 700;
    margin: 20px 20px 18px 20px;
    text-align: left;
    line-height: 55px;
  }
  .masters-title-skinny {
    font-family: nyt-cheltenham-cond,serif;
    font-size: 40px;
    line-height: 44px;
    text-align: center;
    margin: 30px 20px 18px 20px;
  }
  .masters-image {
    max-width: none;
    width: 100%;
  }
  .masters-image-metadata {
    margin: 10px 20px 0 20px;
  }
  .masters-image-description {
    font-size: 14px;
    margin-right: 7px;
  }
  .masters-image-credit {
    font-size: 12px;
    letter-spacing: .12px;
  }
}

.masters-header-top {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.masters-header-top-lg {

}
.masters-header-top-md {

}
.masters-header-top-sm {

}

.masters-header-bottom {
  display: flex;
  flex-direction: column;
  width: inherit;
  max-width: 600px;
}
.masters-header-bottom-lg {
  margin-bottom: 30px;
}
.masters-header-bottom-md {
  margin-bottom: 30px;
}
.masters-header-bottom-sm {
  margin-bottom: 20px;
}

@media only screen and (max-width: 640px) {
  .masters-header-bottom {
    margin-left: 20px;
    margin-right: 20px;
  }
}


.masters-author {
  display: flex;
  flex-direction: row;
}
.masters-author-lg {
  flex-direction: row;
  border-top: 1px solid #EBEBEB;
  border-bottom: 1px solid #EBEBEB;
  padding: 10px 0;
}
.masters-author-md {
  padding: 10px 0;
}
.masters-author-sm {
}

.masters-author-image {
  margin-right: 10px;
}
.masters-author-image-lg {
  width: 80px;
  height: fit-content;
}
.masters-author-image-md {
  width: 50px;
  height: fit-content;
}

.masters-author-metadata {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.masters-author-title {
  font-family: nyt-franklin;
  font-weight: 700;
  color: #363636;
  margin: 0;
}
.masters-author-title-lg {
  font-size: 16px;
}
.masters-author-title-md {
  font-size: 15px;
}
.masters-author-title-sm {
  font-size: 15px;
}
.masters-author-name a {
  color: #363636;
}
.masters-author-name a:hover {
  color: #363636;
}

.masters-author-bio {
  font-family: nyt-franklin;
  font-size: 15px;
  margin: 0;
}
@media only screen and (max-width: 1024px) {
  .masters-author-image {
    width: 40px;
  }
  .masters-author-title-lg {
    font-size: 13px;
  }
  .masters-author-title-md {
    font-size: 14px;
  }
  .masters-author-bio {
    font-size: 13px;
  }
}

.masters-footer {
  display: flex;
  flex-direction: column;
  margin: 0 35px;
  padding: 0 35px;
  border-top: 1px solid #EBEBEB;
}
.masters-footer ul {
  list-style: none;
  padding: 0px;
  display: inline-block;
}
.masters-footer li a {
  font-family: nyt-franklin;
  font-size: 11px;
  padding: 10px;
  color: #666666;
  text-decoration: none;
}
.masters-footer li:hover {
  color: #666666;
  text-decoration: underline;
}
.masters-table {
  border-top: 2px solid black !important;
  border-bottom: 3px solid black !important;
  border-left: 3px solid black !important;
  border-right: 3px solid black !important;
}
.masters-table-heading-title {
  text-align: center;
  font-family: nyt-franklin;
  font-weight: 700;
  font-size: 30px;
  line-height: 30px;
  position: relative;
  height: 80px;
  margin-bottom: -40px;
  overflow: hidden;
  width: 100%;
  border-top: 3px solid black !important;
  border-left: 3px solid black !important;
  border-right: 3px solid black !important;
  border-radius: 50%;
  padding-top: 4px;
}
.cut-off-half {
  height: 40px;
  overflow: hidden;
}
.masters-table-heading-name {
  text-align: center;
  font-family: nyt-franklin;
  font-weight: 700;
  font-size: 18px;
  width: 220px;
  border-bottom: 2px solid black !important;
  border-left: 2px solid black !important;
  border-right: 2px solid black !important;
}
.masters-table-heading {
  text-align: center;
  font-family: nyt-franklin;
  font-weight: 700;
  font-size: 20px;
  width: 42px;
  border-bottom: 2px solid black !important;
  border-left: 2px solid black !important;
  border-right: 2px solid black !important;
  vertical-align: middle;
}
.masters-table-heading-small {
  text-align: center;
  font-family: nyt-franklin;
  font-weight: 700;
  font-size: 16px;
  width: 42px;
  border-bottom: 2px solid black !important;
  border-left: 2px solid black !important;
  border-right: 2px solid black !important;
  vertical-align: middle;
}
.masters-table-data-name {
  font-family: nyt-franklin;
  font-weight: 500;
  font-size: 18px;
  width: 220px;
  border-bottom: 2px solid black !important;
  border-left: 2px solid black !important;
  border-right: 2px solid black !important;
  vertical-align: middle;
}
.masters-table-data {
  text-align: center;
  font-family: nyt-franklin;
  font-weight: 700;
  font-size: 20px;
  border-bottom: 2px solid black !important;
  border-left: 2px solid black !important;
  border-right: 2px solid black !important;
}

.masters-table-data span {
  box-shadow: inset 2px 2px 5px grey !important;
  padding: 4px;
  width: 30px;
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.extra-width span{
  width: 50px;
}

.missed-cut {
  font-size: 16px;
}
.withdrew {
  font-size: 16px;
}

.below-par {
  color: #ed0030;
}
.not-below-par {
  color: #006747;
}
.thru {
  color: #006747;
}

.player-name {
  color: #006747;
  font-style: italic;
}
.user-name {
  font-weight: 700;
}
.wildcard {
  color: #ec2c87;
}

.clickable {
  cursor: pointer;
}
.winner {
  background-color: #107D57;
  color: #FCE300 !important;
}
.winner span{
  box-shadow: inset 2px 2px 5px #006747 !important;
  color: #FCE300 !important;
}

.player-tier-label {
  display: inline-flex;
  font-style: normal;
  font-size: 14px;
  background-color: #006747;
  border-radius: 10%;
  color: white;
  padding: 2px 6px;
  font-family: nyt-franklin;
  width: 35px;
  justify-content: center;
  align-items: center;
}

.total {
  width: 70px;
}

@media only screen and (max-width: 1048px) {
  .masters-table-heading-hole {
    display: none;
  }
  .masters-table-heading-par {
    display: none;
  }
  .hole {
    display: none;
  }
  .masters-table-heading-title {
    border-radius: 30px;
  }
}

@media only screen and (min-width: 1048px) {
  .masters-table-heading-total {
    display: none;
  }
  .small-screen-only {
    display: none;
  }
  .total {
    display: none;
  }
  .thru {
    display: none;
  }
}
@media only screen and (max-width: 1047px) {
  .large-screen-only {
    display: none;
  }
}

.masters-registered-user {
  color: #006747;
}
.masters-registered-users {

}

.masters-btn {
  background-color: #006747 !important;
  border-color: #006747 !important;
  margin-bottom: 20px;
}
.masters-btn:hover {
  background-color: #009b77 !important;
  border-color: #006747 !important;
}

/* Masters End */

/* Footer Start */

.footer-copyright {
  text-align: center;
  margin-bottom: 15px;
}
.footer-links {
  text-align: center;
  padding-bottom: 35px;
}
.footer-links li {
  display: inline-block;
}

/* Footer End */

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.transparent {
  fill: transparent;
}

@media only screen and (min-width: 900px) {
  .hidden-lg {
    display: none !important;
  }
  .shown-sm {
    display: none !important;
  }
  .shown-md {
    display: none !important;
  }
}
@media only screen and (min-width: 600px) and (max-width: 900px) {
  .hidden-md {
    display: none !important;
  }
  .shown-sm {
    display: none !important;
  }
  .shown-lg {
    display: none !important;
  }
}
@media only screen and (max-width: 600px) {
  .hidden-sm {
    display: none !important;
  }
  .shown-md {
    display: none !important;
  }
  .shown-lg {
    display: none !important;
  }
}
