// @font-face {
//   font-family: 'nyt-cheltenham';
//   src: url('../fonts/cheltenham-italic-200.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 200;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-cheltenham';
//   src: url('../fonts/cheltenham-italic-300.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-cheltenham';
//   src: url('../fonts/cheltenham-italic-400.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-cheltenham';
//   src: url('../fonts/cheltenham-italic-500.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 500;
//   font-display: swap;
// }

@font-face {
  font-family: 'nyt-cheltenham';
  src: url('../fonts/cheltenham-italic-700.woff2') format('woff2');
  font-style: italic;
  font-weight: 700;
  font-display: swap;
}

// @font-face {
//   font-family: 'nyt-cheltenham';
//   src: url('../fonts/cheltenham-italic-800.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 800;
//   font-display: swap;
// }

@font-face {
  font-family: 'nyt-cheltenham';
  src: url('../fonts/cheltenham-normal-200.woff2') format('woff2');
  font-style: normal;
  font-weight: 200;
  font-display: swap;
}

@font-face {
  font-family: 'nyt-cheltenham';
  src: url('../fonts/cheltenham-normal-300.woff2') format('woff2');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'nyt-cheltenham';
  src: url('../fonts/cheltenham-normal-400.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'nyt-cheltenham';
  src: url('../fonts/cheltenham-normal-500.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'nyt-cheltenham';
  src: url('../fonts/cheltenham-normal-700.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

// @font-face {
//   font-family: 'nyt-cheltenham';
//   src: url('../fonts/cheltenham-normal-800.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 800;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-cheltenham-cond';
//   src: url('../fonts/cheltenham-cond-normal-300.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-cheltenham-cond';
//   src: url('../fonts/cheltenham-cond-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }

@font-face {
  font-family: 'nyt-cheltenham-cond';
  src: url('../fonts/cheltenham-cond-normal-700.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

// @font-face {
//   font-family: 'nyt-cheltenham-scaps';
//   src: url('../fonts/cheltenham-scaps-normal-300.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
// }

@font-face {
  font-family: 'nyt-cheltenham-small';
  src: url('../fonts/cheltenham-small-italic-400.woff2') format('woff2');
  font-style: italic;
  font-weight: 400;
  font-display: swap;
}

// @font-face {
//   font-family: 'nyt-cheltenham-small';
//   src: url('../fonts/cheltenham-small-italic-700.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
// }

@font-face {
  font-family: 'nyt-cheltenham-small';
  src: url('../fonts/cheltenham-small-normal-400.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'nyt-cheltenham-small';
  src: url('../fonts/cheltenham-small-normal-700.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

// @font-face {
//   font-family: 'nyt-cheltenham-wide';
//   src: url('../fonts/cheltenham-wide-italic-400.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-cheltenham-wide';
//   src: url('../fonts/cheltenham-wide-normal-400.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-cheltenham-xcond';
//   src: url('../fonts/cheltenham-xcond-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-fact';
//   src: url('../fonts/fact-italic-400.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-fact';
//   src: url('../fonts/fact-normal-400.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-fact';
//   src: url('../fonts/fact-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-fact-deck';
//   src: url('../fonts/fact-deck-normal-400.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-fact-deck';
//   src: url('../fonts/fact-deck-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-deck';
//   src: url('../fonts/fact-deck-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-deck-condensed';
//   src: url('../fonts/fact-deck-condensed-normal-400.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-deck-condensed';
//   src: url('../fonts/fact-deck-condensed-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-deck-condensed';
//   src: url('../fonts/fact-deck-condensed-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-display';
//   src: url('../fonts/fact-display-normal-200.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 200;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-display';
//   src: url('../fonts/fact-display-normal-300.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-display';
//   src: url('../fonts/fact-display-normal-400.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-display';
//   src: url('../fonts/fact-display-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-display';
//   src: url('../fonts/fact-display-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-display';
//   src: url('../fonts/fact-display-normal-800.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 800;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-display-condensed';
//   src: url('../fonts/fact-display-condensed-normal-400.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-display-condensed';
//   src: url('../fonts/fact-display-condensed-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-display-condensed';
//   src: url('../fonts/fact-display-condensed-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-fact-display-condensed';
//   src: url('../fonts/fact-display-condensed-normal-800.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 800;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-franklin';
//   src: url('../fonts/franklin-italic-300.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-franklin';
//   src: url('../fonts/franklin-italic-500.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-franklin';
//   src: url('../fonts/franklin-italic-600.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 600;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-franklin';
//   src: url('../fonts/franklin-italic-700.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-franklin';
//   src: url('../fonts/franklin-italic-800.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 800;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-franklin';
//   src: url('../fonts/franklin-italic-900.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 900;
//   font-display: swap;
// }

@font-face {
  font-family: 'nyt-franklin';
  src: url('../fonts/franklin-normal-300.woff2') format('woff2');
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'nyt-franklin';
  src: url('../fonts/franklin-normal-500.woff2') format('woff2');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

// @font-face {
//   font-family: 'nyt-franklin';
//   src: url('../fonts/franklin-normal-600.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
// }

@font-face {
  font-family: 'nyt-franklin';
  src: url('../fonts/franklin-normal-700.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

// @font-face {
//   font-family: 'nyt-franklin';
//   src: url('../fonts/franklin-normal-800.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 800;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-franklin';
//   src: url('../fonts/franklin-normal-900.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-franklin-cword';
//   src: url('../fonts/franklin-cword-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-franklin-small';
//   src: url('../fonts/franklin-small-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-franklin-small';
//   src: url('../fonts/franklin-small-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-franklin-tv';
//   src: url('../fonts/franklin-tv-normal-300.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-franklin-tv';
//   src: url('../fonts/franklin-tv-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-franklin-tv';
//   src: url('../fonts/franklin-tv-normal-600.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-franklin-tv';
//   src: url('../fonts/franklin-tv-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-graphik';
//   src: url('../fonts/graphik-normal-300.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-graphik';
//   src: url('../fonts/graphik-normal-400.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-graphik';
//   src: url('../fonts/graphik-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-graphik';
//   src: url('../fonts/graphik-normal-600.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-graphik';
//   src: url('../fonts/graphik-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-graphik-cond';
//   src: url('../fonts/graphik-cond-normal-400.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-graphik-xcond';
//   src: url('../fonts/graphik-xcond-normal-600.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-imperial';
//   src: url('../fonts/imperial-italic-400.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 400;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-imperial';
//   src: url('../fonts/imperial-italic-600.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 600;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-imperial';
//   src: url('../fonts/imperial-italic-700.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
// }

@font-face {
  font-family: 'nyt-imperial';
  src: url('../fonts/imperial-normal-400.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

// @font-face {
//   font-family: 'nyt-imperial';
//   src: url('../fonts/imperial-normal-600.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
// }

@font-face {
  font-family: 'nyt-imperial';
  src: url('../fonts/imperial-normal-700.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-cursive-100.woff2') format('woff2');
//   font-style: cursive;
//   font-weight: 100;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-cursive-200.woff2') format('woff2');
//   font-style: cursive;
//   font-weight: 200;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-cursive-300.woff2') format('woff2');
//   font-style: cursive;
//   font-weight: 300;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-cursive-500.woff2') format('woff2');
//   font-style: cursive;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-cursive-600.woff2') format('woff2');
//   font-style: cursive;
//   font-weight: 600;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-cursive-700.woff2') format('woff2');
//   font-style: cursive;
//   font-weight: 700;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-cursive-900.woff2') format('woff2');
//   font-style: cursive;
//   font-weight: 900;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-italic-100.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 100;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-italic-200.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 200;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-italic-300.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-italic-500.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-italic-600.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 600;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-italic-700.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-italic-900.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 900;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-normal-100.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-normal-200.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 200;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-normal-300.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-normal-400.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-normal-600.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak';
//   src: url('../fonts/karnak-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }

@font-face {
  font-family: 'nyt-karnak';
  src: url('../fonts/karnak-normal-900.woff2') format('woff2');
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

// @font-face {
//   font-family: 'nyt-karnak-cond';
//   src: url('../fonts/karnak-cond-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak-small';
//   src: url('../fonts/karnak-small-normal-400.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-karnak-small';
//   src: url('../fonts/karnak-small-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-kippenberger';
//   src: url('../fonts/kippenberger-normal-400.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-kippenberger';
//   src: url('../fonts/kippenberger-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-kippenberger';
//   src: url('../fonts/kippenberger-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-kippenberger';
//   src: url('../fonts/kippenberger-normal-900.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-kippenberger-condensed';
//   src: url('../fonts/kippenberger-condensed-normal-400.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 400;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-kippenberger-condensed';
//   src: url('../fonts/kippenberger-condensed-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-kippenberger-condensed';
//   src: url('../fonts/kippenberger-condensed-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-kippenberger-condensed';
//   src: url('../fonts/kippenberger-condensed-normal-900.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-kippenberger-poster';
//   src: url('../fonts/kippenberger-poster-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-kippenberger-poster';
//   src: url('../fonts/kippenberger-poster-normal-900.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-magsans';
//   src: url('../fonts/magsans-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-magsans';
//   src: url('../fonts/magsans-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-magserif';
//   src: url('../fonts/magserif-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-magslab';
//   src: url('../fonts/magslab-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-schnyder-s';
//   src: url('../fonts/schnyder-s-normal-600.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-schnyder-s';
//   src: url('../fonts/schnyder-s-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-schnyder-scond';
//   src: url('../fonts/schnyder-scond-normal-300.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-schnyder-scond';
//   src: url('../fonts/schnyder-scond-normal-600.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 600;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-schnyder-scond';
//   src: url('../fonts/schnyder-scond-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie';
//   src: url('../fonts/stymie-italic-300.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 300;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie';
//   src: url('../fonts/stymie-italic-500.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie';
//   src: url('../fonts/stymie-italic-700.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie';
//   src: url('../fonts/stymie-italic-800.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 800;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie';
//   src: url('../fonts/stymie-normal-100.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 100;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie';
//   src: url('../fonts/stymie-normal-200.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 200;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie';
//   src: url('../fonts/stymie-normal-300.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 300;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie';
//   src: url('../fonts/stymie-normal-500.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 500;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie';
//   src: url('../fonts/stymie-normal-700.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 700;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie';
//   src: url('../fonts/stymie-normal-800.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 800;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie';
//   src: url('../fonts/stymie-normal-900.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 900;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie-small';
//   src: url('../fonts/stymie-small-italic-800.woff2') format('woff2');
//   font-style: italic;
//   font-weight: 800;
//   font-display: swap;
// }
//
// @font-face {
//   font-family: 'nyt-stymie-small';
//   src: url('../fonts/stymie-small-normal-800.woff2') format('woff2');
//   font-style: normal;
//   font-weight: 800;
//   font-display: swap;
// }
