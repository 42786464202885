:root {
  --color-primary: black;
  --color-primary-hover: #9a9a9a;
  --color-secondary: #5a5a5a;
  --background: white;
  --divider-light: #C7C7C7;
  --divider-dark: black;
  --nav-divider-top: lightgrey;
  --nav-divider-bottom: black;

  --game-of-life-background: blue;
  --game-of-life-primary: white;
  --game-of-life-hover: yellow;

  --newspaper: #ffffd4;

  --black: #000000;
  --black-accent: #1a1a1a;
  --mid-black: #5a5a5a;
  --light-black: #9a9a9a;
  --white-accent: #e6e6e6;
  --white: #ffffff;

  --light-grey: #ebebeb;
  --mid-grey: #d6d6d6;
  --dark-grey: #c2c2c2;

  --deep-blue1: #002537;
  --deep-blue2: #003047;
  --deep-blue3: #003c59;
  --deep-blue4: #004c70;

  --red: #e91733;
  --light-red: #f96d78;
  --orange: #ff6633;
  --light-orange: #ffa24b;
  --yellow: #ffda00;
  --light-yellow: #fcdc6a;
  --green: #20af41;
  --light-green: #a7d89c;
  --blue: #207ae9;
  --light-blue: #55aee9;
  --purple: #965ee9;
  --light-purple: #af89e9;
}
//
// [data-theme-color-primary="black-and-white"] { --color-primary: black}
// [data-theme-color-primary-hover="black-and-white"] { --color-primary-hover: green}
// [data-theme-backgrounnd-color="black-and-white"] { --background-color: white}
// [data-theme-divider="black-and-white"] { --divider: #C7C7C7}
// [data-theme-nav-divider-top="black-and-white"] { --nav-divider-top: lightgrey}
// [data-theme-nav-divider-bottom="black-and-white"] { --nav-divider-bottom: black}
//
// [data-theme-color-primary="dark"] { --color-primary: #ffffff}
// [data-theme-color-primary-hover="dark"] { --color-primary-hover: red}
// [data-theme-backgrounnd-color="dark"] { --background-color: #002537}
// [data-theme-divider="dark"] { --divider: #004c70}
// [data-theme-nav-divider-top="dark"] { --nav-divider-top: #003c59}
// [data-theme-nav-divider-bottom="dark"] { --nav-divider-bottom: #003047}
//
// [data-theme-color-primary="red"] { --color-primary: black}
// [data-theme-color-primary-hover="red"] { --color-primary-hover: green}
// [data-theme-backgrounnd-color="red"] { --background-color: red}
// [data-theme-divider="red"] { --divider: #C7C7C7}
// [data-theme-nav-divider-top="red"] { --nav-divider-top: lightgrey}
// [data-theme-nav-divider-bottom="red"] { --nav-divider-bottom: black}
//
// [data-theme-color-primary="orange"] { --color-primary: black}
// [data-theme-color-primary-hover="orange"] { --color-primary-hover: green}
// [data-theme-backgrounnd-color="orange"] { --background-color: orange}
// [data-theme-divider="orange"] { --divider: #C7C7C7}
// [data-theme-nav-divider-top="orange"] { --nav-divider-top: lightgrey}
// [data-theme-nav-divider-bottom="orange"] { --nav-divider-bottom: black}
//
// [data-theme-color-primary="yellow"] { --color-primary: black}
// [data-theme-color-primary-hover="yellow"] { --color-primary-hover: green}
// [data-theme-backgrounnd-color="yellow"] { --background-color: yellow}
// [data-theme-divider="yellow"] { --divider: #C7C7C7}
// [data-theme-nav-divider-top="yellow"] { --nav-divider-top: lightgrey}
// [data-theme-nav-divider-bottom="yellow"] { --nav-divider-bottom: black}
//
// [data-theme-color-primary="green"] { --color-primary: black}
// [data-theme-color-primary-hover="green"] { --color-primary-hover: green}
// [data-theme-backgrounnd-color="green"] { --background-color: green}
// [data-theme-divider="green"] { --divider: #C7C7C7}
// [data-theme-nav-divider-top="green"] { --nav-divider-top: lightgrey}
// [data-theme-nav-divider-bottom="green"] { --nav-divider-bottom: black}
//
// [data-theme-color-primary="blue"] { --color-primary: black}
// [data-theme-color-primary-hover="blue"] { --color-primary-hover: green}
// [data-theme-backgrounnd-color="blue"] { --background-color: blue}
// [data-theme-divider="blue"] { --divider: #C7C7C7}
// [data-theme-nav-divider-top="blue"] { --nav-divider-top: lightgrey}
// [data-theme-nav-divider-bottom="blue"] { --nav-divider-bottom: black}
//
// [data-theme-color-primary="purple"] { --color-primary: black}
// [data-theme-color-primary-hover="purple"] { --color-primary-hover: green}
// [data-theme-backgrounnd-color="purple"] { --background-color: purple}
// [data-theme-divider="purple"] { --divider: #C7C7C7}
// [data-theme-nav-divider-top="purple"] { --nav-divider-top: lightgrey}
// [data-theme-nav-divider-bottom="purple"] { --nav-divider-bottom: black}
